import { BrushType1 } from './Brushes';
import { BrushOptions } from './Brushes/Brush';
import { MainApp } from '../ConvertSvgToCanvas/MainApp';
import { BrushTypes } from '../../types/types';

class OwnBrushes {
  private _selectedBrush!: BrushTypes;

  private canvas: CanvasRenderingContext2D;

  // too: убрать отсюда
  public mainApp: MainApp;

  constructor(canvas: CanvasRenderingContext2D, mainApp: MainApp) {
    this.canvas = canvas;
    this.mainApp = mainApp;
  }

  public get selectedBrush(): BrushTypes {
    return this._selectedBrush;
  }

  public setSelectedBrush(options?: Omit<BrushOptions, 'canvas'>) {
    // todo нужен случайный выбор одной из кистей
    this._selectedBrush = new BrushType1({
      canvas: this.canvas,
      opacity: options?.opacity,
      color: options?.color,
      width: options?.width,
    });
  }
}

export default OwnBrushes;
