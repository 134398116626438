// eslint-disable-next-line import/no-extraneous-dependencies
import GUI from 'lil-gui';
import { MainApp } from '../../ConvertSvgToCanvas/MainApp';
import { BrushTypes } from '../../../types/types';
import { BrushType1, CrayonBrush } from '../Brushes';

// todo: need refactoring params
class DebugGUI {
  public brush: BrushTypes;

  public ctx: CanvasRenderingContext2D;

  private mainApp: MainApp;

  protected gui = new GUI({ title: 'Debug GUI' });

  constructor(
    brush: BrushTypes,
    ctx: CanvasRenderingContext2D,
    mainApp: MainApp,
  ) {
    this.ctx = ctx;
    this.brush = brush;
    this.mainApp = mainApp;
  }

  public initialize(): void {
    const folderName = `Settings ${this.brush.brushName}`;
    const settingsFolder = this.gui.addFolder(folderName);

    // common brush settings

    settingsFolder.add(
      this.brush.getSettings(),
      'width',
      10,
      100,
      1,
    )
      .name('size point')
      .onChange((value: number) => {
        this.mainApp.clearCanvas();
        this.brush.setWidth(value);
        this.mainApp.start(false);
      });

    settingsFolder.add(
      this.brush.getSettings(),
      'opacity',
      0,
      1,
      0.01,
    )
      .name('opacity')
      .onChange((value: number) => {
        this.mainApp.clearCanvas();
        this.brush.setOpacity(value);
        this.mainApp.start(false);
      });

    // individual brush settings

    if (this.brush.brushName === 'Crayon Brush') {
      const brush = this.brush as CrayonBrush;
      settingsFolder.add(
        brush.getSettings(),
        'fat',
        0,
        25,
        1,
      )
        .name('fat')
        .onChange((value: number) => {
          this.mainApp.clearCanvas();
          brush.setFat(value);
          this.mainApp.start(false);
        });
    }

    if (this.brush.brushName === 'Brush Type 1') {
      const brush = this.brush as BrushType1;
      settingsFolder.add(
        brush.getSettings(),
        'dispersion',
        0,
        100,
        1,
      )
        .name('dispersion')
        .onChange((value: number) => {
          this.mainApp.clearCanvas();
          brush.setDispersion(value);
          this.mainApp.start(false);
        });
    }
  }
}

export default DebugGUI;
