import {
  FC, useEffect,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactSVG } from 'react-svg';
import classNames from './Canvas.module.css';
import index from '../../assets/index.svg';
import { MainApp } from '../../modules/ConvertSvgToCanvas/MainApp';
import { Screen } from '../../data/ScreenResolution/Screen';
import { config } from '../../config/config';

const Canvas:FC = () => {
  useEffect(() => {
    setTimeout(() => {
      const brush = new MainApp({ svgId: 'svg', isDebug: false });
      brush.start(config.isAnimate);
    }, 1000);
  }, []);

  return (
    <div className={classNames.root}>
      <canvas
        id="canvas"
        width={Screen.width}
        height={Screen.height}
      />
      <div style={{ display: 'none' }}>
        <ReactSVG src={index} />
      </div>
    </div>
  );
};

export default Canvas;
