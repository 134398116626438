import BrushPoint from '../BrushPoint/BrushPoint';

export interface InterpolateBrushPointsResult {
  interpolatePoints: BrushPoint[];
  distances: number[];
}

const interpolateBrushPoints = (brushPoints: BrushPoint[], step: number): InterpolateBrushPointsResult => {
  const interpolatePoints: BrushPoint[] = [];
  const distances: number[] = [];

  let distanceRest = 0;
  let lastBrushPoint: BrushPoint = brushPoints[0] as BrushPoint;

  brushPoints.forEach((brushPoint, index) => {
    if (index === 0) {
      interpolatePoints.push(brushPoint);
      lastBrushPoint = brushPoint;
      return;
    }

    const dist = lastBrushPoint.getDistanceToBrushPoint(brushPoint);
    const angle = lastBrushPoint.getAngleToBrushPoint(brushPoint);

    distanceRest = dist;
    distances.push(dist);

    while (distanceRest >= step) {
      distanceRest -= step;
      const y = (lastBrushPoint.y) + (Math.cos(angle) * (step));
      const x = (lastBrushPoint.x) + (Math.sin(angle) * (step));
      const interpolatedBrushPoint = new BrushPoint({ x, y, density: dist });
      interpolatePoints.push(interpolatedBrushPoint);
      lastBrushPoint = interpolatedBrushPoint;
    }
  });

  return { distances, interpolatePoints };
};

export default interpolateBrushPoints;
