import React, { FC } from 'react';
import './styles/App.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Canvas from './containers/Canvas/Canvas';
import Debug from './containers/Debug/Debug';

const App: FC = () => (
  <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Canvas />} />
        <Route path="/debug" element={<Debug />} />
      </Routes>
    </BrowserRouter>
  </div>
);

export default App;
