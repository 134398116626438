import {
  ChangeEvent, FC, useCallback, useEffect, useState,
} from 'react';
import { ReactSVG } from 'react-svg';
import classNames from '../Canvas/Canvas.module.css';
import { Screen } from '../../data/ScreenResolution/Screen';
import index from '../../assets/index.svg';
import { Easing } from '../../data/EasingAnimation/Easing';
import { MainApp } from '../../modules/ConvertSvgToCanvas/MainApp';
import json from '../../assets/json/easing.json';

const Debug: FC = () => {
  const [word, setWord] = useState<string>('');
  const [mainApp, setMainApp] = useState<MainApp | null>(null);

  const changeWordHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setWord(e.target.value);
  }, [setWord]);

  const clickButtonHandler = useCallback(() => {
    if (mainApp) {
      for (let i = 0; i < word.length; i++) {
        mainApp.writeNewWord(word[i].toLowerCase());
      }
    }
    setWord('');
  }, [word, mainApp]);

  const downloadJsonEasingHandler = useCallback(() => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    // @ts-ignore
    a.style = 'display: none';
    const jsonDownload = JSON.stringify(json, null, 2);
    const blob = new Blob([jsonDownload], { type: 'application/octet-binary' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'easingSettings.json';
    a.click();
    window.URL.revokeObjectURL(url);
  }, []);

  const selectChangeHandler = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    if (mainApp) mainApp.easingAnimation = e.target.value;
  }, [mainApp]);

  const clearCanvasHandler = useCallback(() => {
    if (mainApp) mainApp.clearCanvas();
  }, [mainApp]);

  useEffect(() => {
    setTimeout(() => {
      const brush = new MainApp({ svgId: 'svg', isDebug: true });
      setMainApp(brush);
    }, 1000);
  }, []);

  return (
    <div>
      <div className={classNames.root}>
        <canvas
          id="canvas"
          width={Screen.width}
          height={Screen.height}
        />
        <div style={{ display: 'none' }}>
          <ReactSVG src={index} />
        </div>
        <div style={{ position: 'absolute', top: 0, left: 0 }}>
          <input
            maxLength={1}
            onChange={changeWordHandler}
          />
          <button
            type="button"
            onClick={clickButtonHandler}
          >
            Start
          </button>
          <button
            type="button"
            onClick={downloadJsonEasingHandler}
          >
            download json easing
          </button>
          <select onChange={selectChangeHandler} name="easing" id="easing">
            {Easing.map((item) => (
              <option key={item} value={item}>{item}</option>
            ))}
          </select>
          <button
            type="button"
            onClick={clearCanvasHandler}
          >
            Clear canvas
          </button>
        </div>
      </div>
    </div>
  );
};

export default Debug;
