export interface ConvertSvgToCoordinates {
  linePath: string[];
  polylinePath: string[];
}

const convertSvgToCoordinates = (id: string): ConvertSvgToCoordinates => {
  const word = document.getElementById(id);
  if (!word) throw new Error(`not found word by id ${id}`);
  const lines = word.getElementsByTagName('line');
  const polyline = word.getElementsByTagName('polyline');

  const linePath: string[] = [];
  const polylinePath: string[] = [];
  for (let i = 0; i < lines.length; i++) {
    const currentLine = lines[i];
    const x1 = currentLine.x1.baseVal.value;
    const x2 = currentLine.x2.baseVal.value;
    const y1 = currentLine.y1.baseVal.value;
    const y2 = currentLine.y2.baseVal.value;
    const string = `${x1},${y1} ${x2},${y2}`;
    linePath.push(string);
  }

  for (let i = 0; i < polyline.length; i++) {
    const currentPolyline = polyline[i];
    const points = currentPolyline.getAttribute('points');
    if (points) polylinePath.push(points);
  }

  return {
    linePath,
    polylinePath,
  };
};

export default convertSvgToCoordinates;
