import { fabric } from 'fabric';

export interface BrushOptions {
  canvas: CanvasRenderingContext2D;
  width?: number;
  opacity?: number;
  color?: string;
}

export abstract class Brush {
  protected ctx: CanvasRenderingContext2D;

  public width = 13;

  public opacity = 0.2;

  public point: fabric.Point = new fabric.Point(0, 0);

  public latest: fabric.Point = new fabric.Point(0, 0);

  public color = '#FF0000';

  protected fabricCanvas: fabric.Canvas;

  constructor(options: BrushOptions) {
    if (!options.canvas) throw new Error('canvas in brush not enabled');

    // todo: вы будущем стоит избавиться так как оно должно инстансироваться извне
    this.fabricCanvas = new fabric.Canvas('canvas', {
      isDrawingMode: false,
      selection: false,
    });

    this.ctx = options.canvas;
    if (options.opacity) this.opacity = options.opacity;
    if (options.width) this.width = options.width;
    if (options.color) this.color = options.color;
  }

  public setWidth(width: number): void {
    this.width = width;
  }

  public setPoint(point: any): void {
    this.latest = this.point;
    this.point = new fabric.Point(point.x, point.y);
  }

  public setOpacity(opacity: number): void {
    this.opacity = opacity;
  }

  public draw(x: number, y: number): void {
    // eslint-disable-next-line no-console
    console.log(x, y);
    this.ctx.stroke();
  }
}

export default Brush;
