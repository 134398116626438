import { fabric } from 'fabric';
import * as TWEEN from '@tweenjs/tween.js';
import opentype from 'opentype.js';
import { interpolateBrushPoints, getEasingFunction } from '../utils';
import BrushPoint from '../BrushPoint/BrushPoint';
import { MainApp } from '../MainApp';
import { BrushTypes } from '../../../types/types';
import easingJSON from '../../../assets/json/easing.json';

export interface SvgPainterOptions {
  textCanvas: CanvasRenderingContext2D;
  mainApp: MainApp;
  brush: BrushTypes;
}

export class SvgPainter {
  public points: any[] = [];

  public textCanvas: CanvasRenderingContext2D;

  public brush: BrushTypes;

  private fontScale = 0;

  public mainApp: MainApp;

  public translate: { x: number, y: number } = { x: 0, y: 0 };

  public x = 0;

  constructor(options: SvgPainterOptions) {
    this.textCanvas = options.textCanvas;
    this.brush = options.brush;
    this.mainApp = options.mainApp;
  }

  public setPoints(points: any[]): void {
    this.points = points;
  }

  public translateWord(id: string): void {
    const buffer = fetch('/BruggermannWIP.otf').then((response) => response.arrayBuffer());
    buffer.then((data) => {
      const font = opentype.parse(data);
      const glyphsArray = [];
      for (let i = 0; i < font.glyphs.length || 0; i++) {
        glyphsArray.push(font.glyphs.get(i));
      }
      const glyph = glyphsArray.find((glyph) => glyph.name === id.toUpperCase());
      if (!glyph) throw new Error('word not found');
      const advancedWidth = glyph.advanceWidth;
      this.textCanvas.translate(0, 0);
      this.textCanvas.translate(this.x, 0);

      this.fontScale = 1 / font.unitsPerEm * 72;

      this.x = advancedWidth - 100;
    });
  }

  public drawSvgToCanvas(isAnimate: boolean, id: string, index: number): void {
    const newArr: BrushPoint[] = [];

    this.points.forEach((item) => {
      const point = new BrushPoint({ x: item.x, y: item.y });
      newArr.push(point);
    });
    const { interpolatePoints } = interpolateBrushPoints(newArr, 3);

    if (isAnimate) this.animateDraw(interpolatePoints, id.toUpperCase(), index);
    else {
      interpolatePoints.forEach((point) => {
        this.brush.setPoint({ x: point.x, y: point.y });
        this.brush.draw(this.translate.x, this.translate.y);
      });
    }
  }

  private animateDraw(interpolatePoints: BrushPoint[], id: string, index: number): void {
    const keyIndex = String(index);
    const word = easingJSON[id as keyof typeof easingJSON];
    // @ts-ignore
    const easingFunction = word[keyIndex as any];
    const func = this.mainApp.easingAnimation
      ? getEasingFunction(this.mainApp.easingAnimation) : getEasingFunction(easingFunction);
    new TWEEN.Tween({ length: 0 })
      .to({ length: interpolatePoints.length - 1 }, 1600)
      .easing(func)
      .onUpdate(({ length }) => {
        const i = Math.floor(length);
        const point = interpolatePoints[i];
        if (length < 10 || length > interpolatePoints.length - 10) {
          const random = fabric.util.getRandomInt(0, 6);
          this.brush.setOpacity(0.05);
          this.brush.setWidth(19 - random);
        } else this.brush.setWidth(13);
        if (point) this.brush.setPoint({ x: point.x, y: point.y });
        this.brush.draw(this.translate.x, this.translate.y);
      })
      .start();
  }
}
