import { fabric } from 'fabric';
import { clamp } from '../utils/index';
import Brush, { BrushOptions } from './Brush';

class BrushType1 extends Brush {
  public brushName = 'Brush Type 1';

  private inkAmount = 20;

  private latestStrokeLength = 2;

  private fat = 10;

  private dispersion = 50;

  constructor(options: BrushOptions) {
    super(options);
  }

  public setDispersion(dispersion: number): void {
    this.dispersion = dispersion;
  }

  public getSettings(): any {
    return {
      width: this.width,
      dispersion: this.dispersion,
      opacity: this.opacity,
    };
  }

  public draw(x: number, y: number): void {
    const difference = this.point.subtract(this.latest);
    const halfWidth = Math.ceil(this.width / 2);
    const stepNum = Math.floor(difference.distanceFrom({ x: 0, y: 0 }) / halfWidth) + 1;
    const dotSize = this.fat * clamp(this.inkAmount / this.latestStrokeLength * 3, 1, 0.5);
    const dotNum = Math.ceil(this.width * this.fat);
    const range = this.width / 2;

    this.ctx.save();
    this.ctx.fillStyle = this.color;
    this.ctx.globalAlpha = this.opacity;
    this.ctx.beginPath();
    for (let i = 0; i < dotNum; i++) {
      for (let j = 0; j < stepNum; j++) {
        const randomInt = fabric.util.getRandomInt(0, range);
        const deg = fabric.util.getRandomInt(0, Math.PI * 2);
        const width = fabric.util.getRandomInt(dotSize, dotSize / 2);
        const height = fabric.util.getRandomInt(dotSize, dotSize / 2);
        const pointX = this.point.x + randomInt * Math.sin(deg) - width;
        const pointY = this.point.y + randomInt * Math.cos(deg) - height;
        this.ctx.roundRect(pointX + x, pointY + y, this.width, this.width, 10);
      }
    }
    this.ctx.fill();
    this.ctx.restore();
  }
}

export default BrushType1;
