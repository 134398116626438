import * as TWEEN from '@tweenjs/tween.js';
import { SvgPainter } from './SvgTools/SvgPainter';
import OwnBrushes from '../BrushTools/OwnBrushes';
import bgImgSource from '../../assets/canvas_bg.jpeg';
import convertSvgToCoordinates from './SvgTools/helpers/convertSvgToCoordinates';
import { Screen } from '../../data/ScreenResolution/Screen';
import { BrushTypes } from '../../types/types';
import GUI from '../BrushTools/GUI/GUI';

export type MainAppType = {
  image?: string;
  svgId?: string;
  isDebug?: boolean;
}

export class MainApp {
  public options!: MainAppType;

  public selectedBrush!: BrushTypes;

  public ctx: CanvasRenderingContext2D | null = null;

  public painter?: SvgPainter = undefined;

  public isDebug = false;

  public easingAnimation: string = '';

  constructor(options: MainAppType) {
    this.options = options;
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    this.ctx = canvas?.getContext('2d');
    if (!this.ctx) return;

    const brushesSettings = new OwnBrushes(this.ctx, this);
    brushesSettings.setSelectedBrush();

    const bgImg = new Image();
    bgImg.src = bgImgSource;
    bgImg.onload = () => {
      this.ctx?.drawImage(bgImg, 0, 0, bgImg.width, bgImg.height, 0, 0, Screen.width, Screen.height);
    };
    this.update();

    this.selectedBrush = brushesSettings.selectedBrush;
    if (options.isDebug) {
      this.isDebug = options.isDebug;
      const gui = new GUI(this.selectedBrush, this.ctx, this);
      gui.initialize();
    }
    this.painter = new SvgPainter({ textCanvas: this.ctx, mainApp: this, brush: this.selectedBrush });
  }

  public update(): void {
    setInterval(() => TWEEN.update(), 1 / 60);
  }

  public writeNewWord(id: string): void {
    if (this.painter) {
      this.ctx?.translate(0, 0);
      this.writeWord(true, id);
    }
  }

  public start(isAnimate = true): void {
    if (!this.painter) throw Error('sry painter broke');
    if (!isAnimate || this.isDebug) {
      this.writeC(this.painter, isAnimate)
        .then(() => this.writeK(this.painter!, isAnimate))
        .then(() => this.writeR(this.painter!, isAnimate));
    } else {
      this.writeC(this.painter, isAnimate)
        .then(() => this.writeK(this.painter!, isAnimate))
        .then(() => this.writeR(this.painter!, isAnimate));
    }
  }

  public clearCanvas(): void {
    this.ctx?.clearRect(0, 0, Screen.width, Screen.height);

    const bgImg = new Image();
    bgImg.src = bgImgSource;
    bgImg.onload = () => {
      this.ctx?.drawImage(bgImg, 0, 0, bgImg.width, bgImg.height, 0, 0, Screen.width, Screen.height);
    };
  }

  public chainsLinesInWord(path: string[], isAnimate: boolean, id: string): Promise<void> {
    const tweens: TWEEN.Tween<any>[] = [];
    this.painter?.translateWord(id);
    path.forEach((item, index) => {
      const delay = (index === 0 || !isAnimate) ? 40 : 1600;
      const tween = new TWEEN.Tween({})
        .delay(delay)
        .to({ }, 0)
        .onUpdate(() => {
          const points = this.parsePath(item);
          this.painter?.setPoints(points);
          this.painter?.drawSvgToCanvas(isAnimate, id, index);
        });
      tweens.push(tween);
    });

    tweens.forEach((item, index) => {
      if (!tweens[index + 1]) return;
      tweens[index].chain(tweens[index + 1]);
    });

    tweens[0].start();
    return new Promise((resolve) => {
      const time = !isAnimate ? 0 : 2500;
      tweens[tweens.length - 1].onComplete(() => {
        setTimeout(() => resolve(), time);
      });
    });
  }

  public writeR(painter: SvgPainter, isAnimate: boolean): Promise<void> {
    const { linePath, polylinePath } = convertSvgToCoordinates('r');
    const pathSummary = [linePath, polylinePath].flat(1);
    return this.chainsLinesInWord(pathSummary, isAnimate, 'r');
  }

  public writeK(painter: SvgPainter, isAnimate: boolean): Promise<void> {
    const { linePath, polylinePath } = convertSvgToCoordinates('e');
    const pathSummary = [linePath, polylinePath].flat(1);
    return this.chainsLinesInWord(pathSummary, isAnimate, 'e');
  }

  public writeWord(isAnimate: boolean, word: string): Promise<void> {
    const { linePath, polylinePath } = convertSvgToCoordinates(word);
    const pathSummary = [linePath, polylinePath].flat(1);
    return this.chainsLinesInWord(pathSummary, isAnimate, 'e');
  }

  public writeC(painter: SvgPainter, isAnimate: boolean): Promise<void> {
    const { linePath, polylinePath } = convertSvgToCoordinates('c');
    const pathSummary = [linePath, polylinePath].flat(1);
    return this.chainsLinesInWord(pathSummary, isAnimate, 'c');
  }

  public parsePath(s: string): any[] {
    const points: any = [];
    const pointStrings = s.split(' ');
    pointStrings.forEach((pointString) => {
      const [x, y] = pointString.split(',');
      points.push({ x: Number(x), y: Number(y) });
    });
    return points;
  }
}
